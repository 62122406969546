






































































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { RctMyApplyViewModel } from '@/modules/recruitment/daos/viewmodels/rct-my-apply-viewmodel'

@Observer
@Component({
  components: {
    'apply-detail-dialog': () => import('@/modules/recruitment/daos/dialog/apply-detail-dialog.vue'),
    'nothing-type-4': () => import('@/components/nothings/nothing-type-4.vue'),
  },
})
export default class extends Vue {
  @Provide() vm = new RctMyApplyViewModel()
}
