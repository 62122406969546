import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { PostStore } from '@/stores/post-store'
import { walletStore } from '@/stores/wallet-store'
import { cloneDeep } from 'lodash'
import { observable } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class RctMyApplyViewModel {
  @observable postStores: PostStore[] = []
  @observable loaded = false
  @observable loadingMore = false
  @observable loadMoreState = { page: 1, pageSize: 9 }
  @observable selectedPostStore = undefined
  @observable openApplyDetailDialog = false
  constructor() {
    this.loadData()
  }

  @observable changeOpenApplyDetailDialog(value, postStore = undefined) {
    this.openApplyDetailDialog = value
    this.selectedPostStore = postStore
  }

  @asyncAction *loadData() {
    try {
      this.loadMoreState = { page: 1, pageSize: 9 }
      const res = yield apiService.recruitmentHandler.fetchMyApplies(this.loadMoreState)
      const applicants = res.data
      if (!applicants?.length) return
      const posts = [] as any
      applicants.map((applicant) => {
        const post = applicant.post
        posts.push({
          ...post,
          myApply: cloneDeep(applicant),
        })
      })
      this.postStores = posts.map((post) => new PostStore(post)) || []
    } catch (e) {
    } finally {
      this.loaded = true
    }
  }

  @asyncAction *loadMore() {
    try {
      this.loadingMore = true
      this.loadMoreState.page += 1
      const res = yield apiService.recruitmentHandler.fetchMyApplies(this.loadMoreState)
      const applicants = res.data
      if (!applicants?.length) return
      const posts = [] as any
      applicants.map((applicant) => {
        const post = applicant.post
        posts.push({
          ...post,
          myApply: applicant,
        })
      })
      const newPostStores = posts.map((post) => new PostStore(post)) || []
      this.postStores = [...this.postStores, ...newPostStores]
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.loadingMore = false
    }
  }
}
